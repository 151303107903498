import React from "react";
import "./Map.scss";
function Map() {
  return (
    <div className='map-container'>
      <div className='sigma_map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3463.70123504128!2d78.53634571110804!3d29.75735863201186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39097b8c75f59f89%3A0x3f10230bd896aa19!2sShri%20Sidhbali%20Baba%20Dham%20Mandir%2C%20Kotdwar%2C%20Uttarakhand!5e0!3m2!1sen!2sin!4v1687504242625!5m2!1sen!2sin'
          allowfullscreen=''
        ></iframe>
      </div>
    </div>
  );
}

export default Map;
