import React, { useState } from "react";
import "./About.scss";
import sidhbalibaba_img from "../../../../../../../Asset/sidhbalibaba.jpg";
import RamHanuman from "../../../../../../../Asset/RamHanuman.png";
import HanumanJi from "../../../../../../../Asset/HanumanJi.png";
import omLogo from "../../../../../../../Asset/om.png";
import { useSelector } from "react-redux";

function About() {
  const [moreInfo, setMoreInfo] = useState(false);
  const toggleInfo = () => {
    setMoreInfo(!moreInfo);
  };
  const langData = useSelector((data) => data.product.Language);
  return (
    <div className='about-page-container'>
      <section className='about-page-container'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 mb-lg-30'>
              <div className='img-group'>
                <div className='img-group-inner'>
                  <img
                    src={sidhbalibaba_img}
                    alt='about'
                    className='middle-img'
                  />
                  <span></span>
                  <span></span>
                </div>
                <img className='outer-images' src={RamHanuman} alt='Shri Ram' />
                <img
                  className='outer-images'
                  src={HanumanJi}
                  alt='Hanuman ji'
                />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='me-lg-30'>
                <div className='section-title mb-0 text-start'>
                  <p className='subtitle'>
                    {" "}
                    <img src={omLogo} alt='om-logo' />
                    {langData?.HomePage?.title01}
                  </p>
                  <h4 className='title'> {langData?.HomePage?.subtitle01}</h4>
                  <h4 className='title'> {langData?.HomePage?.subtitle02}</h4>
                </div>
                <ul className='sigma_list list-2 mb-0'>
                  <li>{langData?.HomePage?.key_word01}</li>
                  <li>{langData?.HomePage?.key_word02}</li>
                  <li>{langData?.HomePage?.key_word03}</li>
                  <li>{langData?.HomePage?.key_word04}</li>
                </ul>
                <p className='blockquote bg-transparent'>
                  {langData?.HomePage?.info01}
                </p>
                {moreInfo ? (
                  <p className='more-info bg-transparent'>
                    {langData?.HomePage?.moreinfo}
                  </p>
                ) : (
                  <p></p>
                )}

                <a
                  onClick={() => toggleInfo()}
                  className='sigma_btn-custom light'
                >
                  {moreInfo
                    ? langData?.HomePage?.showless
                    : langData?.HomePage?.showmore}

                  <i className='far fa-arrow-right'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
