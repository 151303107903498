import React, { useState } from "react";
import { Drawer, Divider } from "@mui/material";
import { Button } from "reactstrap";
import "./Drawer.scss";
import { IoMdCube } from "react-icons/io";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { menuItems } from "./menuItems";
import DropDown from "./DropDown/DropDown";
import { buttonsData } from "../NavBars";
import { useSelector } from "react-redux";
import TempleLogoEng from "../../../../Asset/Sidahbali_orange_eng.png";
import TempleLogoHnd from "../../../../Asset/Sidhbali_logo_hindi 1.png";
function Drawers() {
  const stateValue = useSelector((data) => data?.product?.state_val);
  const [anchor, setAnchor] = useState(false);
  const toggleDrawer = (open) => {
    setAnchor(open);
  };

  return (
    <div className='drawer-container'>
      <IoMdCube onClick={() => toggleDrawer(true)} />
      <Drawer
        className='main-drawer'
        anchor='left'
        open={anchor}
        onClose={() => toggleDrawer(false)}
      >
        <div className='mobile-menu'>
          <div className='img-container'>
            {stateValue ? (
              <img src={TempleLogoHnd} alt='logo' />
            ) : (
              <img src={TempleLogoEng} alt='logo' />
            )}
          </div>
          <div className='menu-container'>
            {/* {menuItems.map((data) => {
              return <DropDown data={data} key={data.id} />;
            })} */}
            {buttonsData.map((data, index) => {
              return (
                <>
                  {" "}
                  <Link
                    onClick={() => toggleDrawer(false)}
                    to={data.link}
                    key={index}
                  >
                    <Button color='link'>
                      {data.icon}
                      {data.title}
                    </Button>
                  </Link>
                  <Divider />
                </>
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Drawers;
