import { configureStore } from "@reduxjs/toolkit";
import { LanguageReducer } from "./Reducers/Lang_Reducer";

const store = configureStore({
  reducer: {
    product: LanguageReducer.reducer,
  },
});

export default store;
