import React from "react";
import PagesBg from "../Pages_Background/PagesBg";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import "./Volunteers.scss";
import { useSelector } from "react-redux";
const IconsData = [
  {
    link: "https://www.facebook.com/mahantdiliprawat?mibextid=ZbWKwL",
    icon: <FaFacebookF />,
  },
  {
    link: "https://www.instagram.com/mahantdiliprwt/?igshid=MzNlNGNkZWQ4Mg%3D%3D",
    icon: <FaInstagram />,
  },
  {
    link: "https://twitter.com/MahantDilipRwt?t=aZGtWMT2v88OXF8GvbPjdQ&s=08",
    icon: <FaTwitter />,
  },
];
function Volunteers() {
  const langData = useSelector((data) => data.product.Language);
  return (
    <div className='volunteers-container'>
      <PagesBg title={"Volunteers"} />
      <div className='sigma_volunteers_01 info-container'>
        <div className='sigma_volunteers volunteers-4'>
          <div className='sigma_volunteers-body main-container'>
            <div className='sigma_volunteers-thumb top-image'>
              <img
                src={langData?.volunteers?.Mahantji_Data?.img}
                alt='Dilip Singh Rawat'
              />
            </div>
            <div className='sigma_volunteers-body'>
              <div className='sigma_volunteers-info'>
                <p>{langData?.volunteers?.Mahantji_Data?.designation}</p>
                <h5>{langData?.volunteers?.Mahantji_Data?.name}</h5>
                <div className='icons_container'>
                  {IconsData.map((data, index) => {
                    return (
                      <a
                        href={data.link}
                        key={index}
                        target='_blank'
                        className='icon-link'
                      >
                        {data.icon}
                      </a>
                    );
                  })}
                </div>

                <p className='volunteers_details'>
                  {langData?.volunteers?.Mahantji_Data?.details}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='info-container'>
        {langData?.volunteers?.VolunteersData.map((data, index) => {
          return (
            <div key={index} className='sigma_volunteers volunteers-4'>
              <div className='sigma_volunteers-body'>
                <div className='sigma_volunteers-thumb'>
                  <img src={data.img} alt={data.name} />
                </div>
                <div className='sigma_volunteers-body'>
                  <div className='sigma_volunteers-info'>
                    <p>{data.designation}</p>
                    <h5>{data.name}</h5>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='other_info_container'>
        {langData?.volunteers?.other_volunteers.map((data, index) => {
          return (
            <div className='other_info'>
              <h5>{data.name} </h5>
              <p>{data.designation}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Volunteers;
