import React from "react";
import "./App.css";

import Template from "./View/Component/Template/Template";
import Home1 from "./View/Component/Carousel/Content/Home1/Home1";

function App() {
  return (
    <>
      <Template />
    </>
  );
}

export default App;
