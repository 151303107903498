import React from "react";
import "./Contact.scss";
import omlogo from "../../../Asset/om.png";
import PagesBg from "../Pages_Background/PagesBg";
import Map from "./Map/Map";
import ContactDetails from "./ContactDetails/ContactDetails";
import Quote from "../About_us/Other/Quote/Quote";
function Contact() {
  return (
    <div className='contact-container'>
      <PagesBg title={"Contact Us"} />
      <div>
        <Map />
        <ContactDetails />
        <Quote />
      </div>
    </div>
  );
}

export default Contact;
