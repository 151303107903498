import React from "react";
import "./ContactDetails.scss";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhoneCall, FiMapPin } from "react-icons/fi";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
function ContactDetails() {
  const langData = useSelector((data) => data.product.Language);
  const InfoData = [
    {
      Icon: <HiOutlineMail />,
      title: langData?.contact?.email_title,
      P1: "-",
      P2: "Sidhbalimandirkotdwar@gmail.com",

      heading: langData?.contact?.email_address,
    },
    {
      Icon: <FiPhoneCall />,
      title: langData?.contact?.phone_title,
      P1: " +91 94120 81421 ",
      P2: " +91 94563 68371 ",
      heading: langData?.contact?.phone_number,
    },
    {
      Icon: <FiMapPin />,
      title: langData?.contact?.location_title,
      P1: langData?.contact?.address01,
      P2: langData?.contact?.address02,
      heading: langData?.contact?.location,
    },
  ];
  return (
    <div className='contat-details-container'>
      {" "}
      <div className='section section-padding pt-0'>
        <div className='container'>
          <div className='row'>
            {InfoData.map((data, index) => {
              return (
                <div key={index} className='col-lg-4'>
                  <div className='sigma_icon-block text-center light icon-block-7'>
                    <i className='flaticon-email'>{data.Icon}</i>
                    <div className='sigma_icon-block-content'>
                      <span>
                        {data.title}
                        <i className='far fa-arrow-right'>
                          <AiOutlineArrowRight />
                        </i>
                      </span>
                      <h5>{data.heading}</h5>
                      <p>{data.P1}</p>
                      <p>{data.P2}</p>
                    </div>
                    <div className='icon-wrapper'>
                      <i className='flaticon-email'>{data.Icon}</i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
