import React from "react";
import "./AboutUs.scss";
import omlogo from "../../../Asset/om.png";
import Info from "./Other/Info/Info";
import Info01 from "./Other/Info01/Info01";
import PagesBg from "../Pages_Background/PagesBg";
function AboutUs() {
  return (
    <div className='about-us-main'>
      <PagesBg title={"About Us"} />
      <Info />
    </div>
  );
}

export default AboutUs;
