import React, { useEffect } from "react";
import "./NavBar.scss";
import { Button } from "reactstrap";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { AiOutlinePhone, AiOutlineHome } from "react-icons/ai";
import { BiGroup } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
import { GiUsaFlag } from "react-icons/gi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { languageAction } from "../../../Application/Store/Actions/Action";
import Drawers from "./Drawer/Drawer";
import unitedFlag from "../../../Asset/united-states.png";
import IndiaFlag from "../../../Asset/india.png";
import TempleLogoEng from "../../../Asset/Sidahbali_orange_eng.png";
import TempleLogoHnd from "../../../Asset/Sidhbali_logo_hindi 1.png";
import { FaRegFileExcel } from "react-icons/fa";
export const buttonsData = [
  { title: "HOME", link: "/", icon: <AiOutlineHome /> },
  { title: "ABOUT", link: "/about-us", icon: <BiGroup /> },
  { title: "CONTACT", link: "/contact", icon: <BsTelephone /> },
  { title: "FAQ", link: "/faq", icon: <FaQuestionCircle /> },
  { title: "BhandaraBookings", link: "/bhandara-bookings", icon: <FaRegFileExcel />}
];
function NavBars() {
  const stateValue = useSelector((data) => data?.product?.state_val);
  const dispatch = useDispatch();
  const selectLanguage = (val) => {
    dispatch(languageAction.changeLanguage(val));
  };
  return (
    <>
      <div className='nav-bar-container'>
        <div className='sigma_logo-wrapper'>
          <a>
            {stateValue ? (
              <img src={TempleLogoHnd} alt='logo' />
            ) : (
              <img src={TempleLogoEng} alt='logo' />
            )}
          </a>
          <div className='sigma_header-controls'>
            <Drawers />
          </div>
        </div>
        <div className='nav-middle'>
          <div className='logo-pic'>
            {stateValue ? (
              <img src={TempleLogoHnd} alt='logo' />
            ) : (
              <img src={TempleLogoEng} alt='logo' />
            )}
          </div>
          <div className='upper'>
            <div className='info-items'>
              <a href='tel:+91 94563 68371'>
                <i className='fal fa-phone'></i> +91 94563 68371
              </a>{" "}
              <a href='tel:+9 194120 81421'>
                <i className='fal fa-phone'></i>+91 9412 081421
              </a>
              <a href='mailto:info@example.com'>
                <i className='fal fa-envelope'></i>{" "}
                Sidhbalimandirkotdwar@gmail.com
              </a>
            </div>
            <div className='lang-items'>
              {stateValue ? (
                <h4 onClick={() => selectLanguage(false)} variant='text'>
                  <img src={unitedFlag} alt='united state flag' />
                  <h4>English</h4>
                </h4>
              ) : (
                <h4 onClick={() => selectLanguage(true)} variant='text'>
                  <img src={IndiaFlag} alt='India flag' />
                  <h4>हिंदी</h4>
                </h4>
              )}
            </div>
          </div>
          <div className='lower'>
            <div className='menu-container'>
              {/* <Menu /> */}
              {buttonsData.map((data, index) => {
                return (
                  <Link to={data.link} key={index}>
                    <Button color='link'>
                      {data.icon}
                      {data.title}
                    </Button>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className='nav-donation'>
          <div className='donate-button'>
            <Link to='/broadcast'>
              <Button variant='contained' size='medium'>
                Broadcast
              </Button>
            </Link>
          </div>
          <div className='phone-button'>
            <div className='lang-items'>
              {stateValue ? (
                <h4 onClick={() => selectLanguage(false)} variant='text'>
                  <img src={unitedFlag} alt='united state flag' />
                  <h4>English</h4>
                </h4>
              ) : (
                <h4 onClick={() => selectLanguage(true)} variant='text'>
                  <img src={IndiaFlag} alt='India flag' />
                  <h4>हिंदी</h4>
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBars;
