import React, { useState } from "react";
import { Button, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";

function DropDown({ data }) {
  const [open, SetOPen] = useState(false);

  const toggleMenu = (val) => {
    SetOPen(val);
  };
  return (
    <>
      <>
        <Button
          onClick={() => toggleMenu(!open)}
          className='button-item'
          variant='text'
        >
          <span>{data.head}</span>
          <span>
            <AiOutlinePlus />
          </span>
        </Button>
        <Divider />
      </>
      ;
      {open ? (
        <div className='menu-items'>
          {data.sub.map((item, index) => (
            <div key={index}>
              <Link to={item.link}>
                <Button className='button-item' variant='text'>
                  <span>{item.title}</span>
                </Button>
              </Link>

              <Divider />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default DropDown;
