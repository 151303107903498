import React from "react";
import { useSelector } from "react-redux";
import "./Info.scss";
import Education01 from "../../../../../Asset/Work/Education01.jpeg";
import Education02 from "../../../../../Asset/Work/Education02.jpeg";
import Education03 from "../../../../../Asset/Work/Education03.jpeg";
import Health01 from "../../../../../Asset/Work/Health01.jpeg";
import Health02 from "../../../../../Asset/Work/Health02.jpeg";
import Health03 from "../../../../../Asset/Work/Health03.jpeg";
import Other01 from "../../../../../Asset/Work/Other01.jpeg";
import Other02 from "../../../../../Asset/Work/Other02.jpeg";
import Other03 from "../../../../../Asset/Work/Other03.jpeg";
import Arti from "../../../../../Asset/AboutPage/ArtiImage.jpg";
import logoImg from "../../../../../Asset/om.png";
function Info01() {
  const langData = useSelector((data) => data.product.Language);
  return (
    <section className='section approach-info'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-6'>
            <div className='me-lg-30'>
              <div className='section-title mb-0 text-start'>
                <p className='subtitle'>
                  <img src={logoImg} />
                  {langData?.AboutUsPage?.aproach}
                </p>
                <h4 className='title'>
                  {langData?.AboutUsPage?.aproach_title}
                </h4>
              </div>
              <p className='blockquote bg-transparent'>
                {langData?.AboutUsPage?.aproach_header}
              </p>

              <ul className='nav nav-tabs' id='myTab' role='tablist'>
                <li className='nav-item'>
                  <a
                    className='nav-link active'
                    id='home-tab'
                    data-bs-toggle='tab'
                    href='#home'
                    role='tab'
                    aria-controls='home'
                    aria-selected='true'
                  >
                    {" "}
                    <i className='fas fa-om'></i>{" "}
                    {langData?.AboutUsPage?.education_title}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    id='profile-tab'
                    data-bs-toggle='tab'
                    href='#profile'
                    role='tab'
                    aria-controls='profile'
                    aria-selected='false'
                  >
                    {" "}
                    <i className='far fa-church'></i>{" "}
                    {langData?.AboutUsPage?.health_title}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link'
                    id='contact-tab'
                    data-bs-toggle='tab'
                    href='#contact'
                    role='tab'
                    aria-controls='contact'
                    aria-selected='false'
                  >
                    {" "}
                    <i className='far fa-user'></i>{" "}
                    {langData?.AboutUsPage?.other_title}
                  </a>
                </li>
              </ul>

              <div className='tab-content' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='home'
                  role='tabpanel'
                  aria-labelledby='home-tab'
                >
                  <p>{langData?.AboutUsPage?.education}</p>{" "}
                  <div className='images-container'>
                    <img src={Education01} alt='School' />
                    <img src={Education02} alt='School' />
                    <img src={Education03} alt='School' />
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='profile'
                  role='tabpanel'
                  aria-labelledby='profile-tab'
                >
                  <p>{langData?.AboutUsPage?.health}</p>{" "}
                  <div className='images-container'>
                    <img src={Health01} alt='Health' />
                    <img src={Health02} alt='Health' />
                    <img src={Health03} alt='Health' />
                  </div>
                </div>
                <div
                  className='tab-pane fade'
                  id='contact'
                  role='tabpanel'
                  aria-labelledby='contact-tab'
                >
                  <p>{langData?.AboutUsPage?.other01}</p>
                  <p>{langData?.AboutUsPage?.other02}</p>
                  <div className='images-container'>
                    <img src={Other01} alt='Other Work' />
                    <img src={Other02} alt='Other Work' />
                    <img src={Other03} alt='Other WOrk' />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <img src={Arti} alt='Arti' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info01;
