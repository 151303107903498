import React from "react";
import "./Info.scss";
import Info01 from "../Info01/Info01";
import Quote from "../Quote/Quote";
import logoImg from "../../../../../Asset/om.png";
import OMImage from "../../../../../Asset/AboutPage/OM_Image.png";
import TempleImage from "../../../../../Asset/AboutPage/Temple_Image.png";
import sidhbalibaba_img from "../../../../../Asset/sidhbalibaba.jpg";
import ShaniDev from "../../../../../Asset/AboutPage/ShaniDev.png";
import SadhuMaharaj from "../../../../../Asset/AboutPage/SwamiJI.png";
import TemplDay from "../../../../../Asset/AboutPage/TempleDay.png";
import TemplNight from "../../../../../Asset/AboutPage/TempleNight.png";
import Hanumanji from "../../../../../Asset/AboutPage/god-hanuman-png-hd.png";
import shivji from "../../../../../Asset/shivji.jpg";
import shivling from "../../../../../Asset/shivling.jpg";
import ShivjiParvti from "../../../../../Asset/AboutPage/ShankarParvati.png";
import { useSelector } from "react-redux";

function Info() {
  const langData = useSelector((data) => data.product.Language);
  return (
    <div className='info-main-container'>
      <section className='section'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 d-none d-lg-block'>
              <div className='sigma_img-box'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={sidhbalibaba_img} alt='sidhbalibaba' />
                    <img className='ms-0' src={shivji} alt='Shivaji' />
                  </div>
                  <div className='col-lg-6 mt-0 mt-lg-5'>
                    <img src={shivling} alt='shivling' />
                    <img
                      className='ms-0'
                      src={ShivjiParvti}
                      alt='Shivaji Parvati'
                    />
                  </div>
                </div>{" "}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={SadhuMaharaj} alt='Sadhu Maharaj' />
                    <img className='ms-0' src={ShaniDev} alt='Shani Dev' />
                  </div>
                  <div className='col-lg-6 mt-0 mt-lg-5'>
                    <img src={TemplDay} alt='temple day' />
                    <img className='ms-0' src={TemplNight} alt='temple night' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='me-lg-30'>
                <div className='section-title mb-0 text-start'>
                  <p className='subtitle'>
                    <img src={logoImg} alt='logo-img' />{" "}
                    <span>{langData?.AboutUsPage?.title}</span>
                  </p>
                  <h4 className='title'>{langData?.AboutUsPage?.title01}</h4>
                </div>
                <p className='blockquote bg-transparent'>
                  {langData?.AboutUsPage?.subtitle01}
                </p>
                <div className='sigma_icon-block icon-block-3'>
                  <div className='icon-wrapper'>
                    <img src={OMImage} alt='Om Image' />
                  </div>
                  <div className='sigma_icon-block-content'>
                    <h5> {langData?.AboutUsPage?.temple} </h5>
                    <p>{langData?.AboutUsPage?.temple_heading}</p>
                  </div>
                </div>
                <div className='sigma_icon-block icon-block-3'>
                  <div className='icon-wrapper'>
                    <img src={TempleImage} alt='Temple Image' />
                  </div>
                  <div className='sigma_icon-block-content puja-container'>
                    <h5> {langData?.AboutUsPage?.puja} </h5>
                    {langData?.AboutUsPage?.puja_content.map((data, index) => {
                      return (
                        <div key={index} className='content-container'>
                          <h6>{data.heading}</h6>
                          {data.subtitle.map((title, index) => {
                            return <p key={index}>{title}</p>;
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className='sigma_icon-block icon-block-3'>
                  <div className='icon-wrapper'>
                    <img src={Hanumanji} alt='Hanumanji' />
                  </div>
                  <div className='sigma_icon-block-content'>
                    <h5> {langData?.AboutUsPage?.Anniversary}</h5>
                    <p>{langData?.AboutUsPage?.Anniversary_heading}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Info01 />
      <Quote />
    </div>
  );
}

export default Info;
