import React from "react";
import "./Home1.scss";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import TempleLogoEng from "../../../../../../src/Asset/Sidahbali_orange_eng.png";
import TempleLogoHnd from "../../../../../../src/Asset/Sidhbali_logo_hindi 1.png";
import { useSelector } from "react-redux";
function Home1({ img, header, title }) {
  const stateValue = useSelector((data) => data?.product?.state_val);
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
      }}
      className='home1-container'
    >
      <div className='mobile_header'>
        {stateValue ? (
          <img src={TempleLogoHnd} alt='logo' />
        ) : (
          <img src={TempleLogoEng} alt='logo' />
        )}
      </div>
      <div className='head-content'>
        <h1 className='title'>{header}</h1>
        <p className='blockquote mb-0 bg-transparent '>{title}</p>
        {/* <div className='section-button'>
          <Link to='/contact'>
            {" "}
            <Button color='primary'>
              Join Today <AiOutlineArrowRight />
            </Button>
          </Link>
          <Link to='/about-us'>
            <Button color='primary' className='white'>
              View Services <AiOutlineArrowRight />
            </Button>
          </Link>
        </div> */}
      </div>
      <div className='empty-content'></div>
    </div>
  );
}

export default Home1;
