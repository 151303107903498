import React from "react";
import "./Preloader.scss";
import logo from "../../../Asset/om.svg";
function Preloader() {
  return (
    <div className='sigma_preloader'>
      <img src={logo} alt='preloader' />
    </div>
  );
}

export default Preloader;
