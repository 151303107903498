import React from "react";
import Slider from "./Slider";
import About from "./OtherPages/About_us/About";
import Servicespage from "./OtherPages/Services/Servicespage.js";
import PujaTime from "./OtherPages/PujeTime/PujaTime";
function Main_Home() {
  return (
    <div>
      <Slider />
      <About />
      <PujaTime />
      <Servicespage />
    </div>
  );
}

export default Main_Home;
