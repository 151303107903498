import React from "react";
import "./services_page.scss";
import omlogo from "../../../../../../../Asset/om.png";
import { Link } from "react-router-dom";
import temple_icon from "../../../../../../../Asset/temple.png";
import swastik from "../../../../../../../Asset/pooja.png";
import pandit from "../../../../../../../Asset/hindu.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";

function Servicespage() {
  const langData = useSelector((data) => data.product.Language);
  return (
    <div className='services_page'>
      <div className='section section-padding pattern-squares dark-bg-2'>
        <div className='container'>
          <div className='section-title text-start'>
            <p className='subtitle'>
              <img src={omlogo} alt='logo' />
              {langData?.HomePage?.subtitle03}
            </p>
            <h4 className='title text-white'> {langData?.HomePage?.title02}</h4>
          </div>

          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <Link to='/about-us' className='sigma_service style-1 primary-bg'>
                <div className='sigma_service-thumb'>
                  <i className='text-white flaticon-temple'>
                    <img src={temple_icon} alt='temple-icon' />
                  </i>
                </div>
                <div className='sigma_service-body'>
                  <h5 className='text-white'>
                    {langData?.HomePage?.about_temple}
                  </h5>
                  <p className='text-white'>
                    {langData?.HomePage?.about_temple_heading}
                  </p>
                </div>
                <span className='btn-link text-white'>
                  Learn More{" "}
                  <i className='text-white far fa-arrow-right'>
                    <AiOutlineArrowRight />
                  </i>
                </span>
              </Link>
            </div>

            <div className='col-lg-4 col-md-6 mt-negative-sm'>
              <Link
                to='/volunteers'
                className='sigma_service style-1 secondary-bg'
              >
                <div className='sigma_service-thumb'>
                  <i className='custom-primary flaticon-hindu-1'>
                    <img src={pandit} alt='pandit-icon' />
                  </i>
                </div>
                <div className='sigma_service-body'>
                  <h5 className='text-white'>
                    {" "}
                    {langData?.HomePage?.our_executives}
                  </h5>
                  <p className='text-white'>
                    {langData?.HomePage?.our_executives_heading}
                  </p>
                </div>
                <span className='text-white btn-link'>
                  Learn More{" "}
                  <i className='text-white far fa-arrow-right'>
                    <AiOutlineArrowRight />
                  </i>
                </span>
              </Link>
            </div>

            <div className='col-lg-4 col-md-6 mt-negative-sm third-container'>
              <Link to='/broadcast' className='sigma_service style-1 bg-white'>
                <div className='sigma_service-thumb'>
                  <i className='flaticon-pooja'>
                    <img src={swastik} alt='swastik-icon' />
                  </i>
                </div>
                <div className='sigma_service-body'>
                  <h5> {langData?.HomePage?.prayers}</h5>
                  <p>{langData?.HomePage?.prayers_heading}</p>
                </div>
                <span className='btn-link'>
                  Learn More
                  <i className='far fa-arrow-right'>
                    <AiOutlineArrowRight />
                  </i>
                </span>
              </Link>
            </div>
          </div>

          {/* <div className='text-end'>
            <Link to='/faq' className='btn-link text-white'>
              Get Started Now{" "}
              <i className='custom-primary far fa-arrow-right'>
                {" "}
                <AiOutlineArrowRight />
              </i>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Servicespage;
