import React from "react";
import "./Info_card.scss";
import { useSelector } from "react-redux";

function Info_card() {
  const langData = useSelector((data) => data.product.Language);
  const data = langData?.FAQ;
  const cardData = [
    {
      one: [
        {
          data_bs_target: "#generalOne",
          aria_expanded: "true",
          aria_controls: "generalOne",
          title: data?.question01,
          Id: "generalOne",
          class: "collapse show",
          Info: data.question01_ans,
        },
        {
          data_bs_target: "#generalTwo",
          aria_expanded: "false",
          aria_controls: "generalTwo",
          title: data?.question03,
          Id: "generalTwo",
          class: "collapse",
          Info: data.question03_ans,
        },
        {
          data_bs_target: "#generalThree",
          aria_expanded: "false",
          aria_controls: "generalThree",
          title: data?.question02,
          Id: "generalThree",
          class: "collapse",
          Info: data?.question02_ans,
        },
        {
          data_bs_target: "#generalSeven",
          aria_expanded: "false",
          aria_controls: "generalSeven",
          title: data?.question07,
          Id: "generalSeven",
          class: "collapse",
          Info: data?.question07_ans,
        },
      ],
    },
    {
      two: [
        {
          data_bs_target: "#generalFour",
          aria_expanded: "true",
          aria_controls: "generalFour",
          title: data?.question05,
          Id: "generalFour",
          class: "collapse show",
          Info: data?.question05_ans,
        },
        {
          data_bs_target: "#generalFive",
          aria_expanded: "false",
          aria_controls: "generalFive",
          title: data?.question04,
          Id: "generalFive",
          class: "collapse",
          Info: data?.question04_ans,
        },
        {
          data_bs_target: "#generalSix",
          aria_expanded: "false",
          aria_controls: "generalSix",
          title: data?.question06,
          Id: "generalSix",
          class: "collapse",
          Info: data?.question06_ans,
        },
        {
          data_bs_target: "#generalEight",
          aria_expanded: "false",
          aria_controls: "generalEight",
          title: data?.question08,
          Id: "generalEight",
          class: "collapse",
          Info: data?.question08_ans,
        },
      ],
    },
  ];
  return (
    <div className='info-card-container'>
      {" "}
      <div className='section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='accordion with-gap' id='generalFAQExample'>
                {cardData[0].one.map((data, index) => {
                  return (
                    <div key={index} className='card'>
                      <div
                        className='card-header'
                        data-bs-toggle='collapse'
                        role='button'
                        data-bs-target={data.data_bs_target}
                        aria-expanded={data.aria_expanded}
                        aria-controls={data.aria_controls}
                      >
                        {data.title}
                      </div>

                      <div
                        id={data.Id}
                        className={data.class}
                        data-bs-parent='#generalFAQExample'
                      >
                        <div className='card-body'>{data.Info}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='accordion with-gap' id='generalFAQExample2'>
                {cardData[1].two.map((data, index) => {
                  return (
                    <div key={index} className='card'>
                      <div
                        className='card-header'
                        data-bs-toggle='collapse'
                        role='button'
                        data-bs-target={data.data_bs_target}
                        aria-expanded={data.aria_expanded}
                        aria-controls={data.aria_controls}
                      >
                        {data.title}
                      </div>

                      <div
                        id={data.Id}
                        className={data.class}
                        data-bs-parent='#generalFAQExample2'
                      >
                        <div className='card-body'>{data.Info}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info_card;
