import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from "reactstrap";
import { useSelector } from "react-redux";

import "./PujaTime.scss";
function PujaTime() {
  const langData = useSelector((data) => data.product.Language);
  const homeData = langData?.artiSchedule?.PujaData;
  return (
    <div className='puja-time-container'>
      {homeData.map((data, index) => {
        return (
          <Card key={index} className='card-container'>
            <img alt='Evening-Arti' src={data.img} />
            <CardBody className='card-body'>
              <CardTitle className='card-title' tag='h5'>
                {data.title}
              </CardTitle>
              <CardSubtitle className='card-text'>{data.subtitle}</CardSubtitle>
            </CardBody>
          </Card>
        );
      })}
    </div>
  );
}

export default PujaTime;
{
  /* <div className='evening-arti'>
<img src={EveningArti} alt='evening-arti' />
</div> */
}
