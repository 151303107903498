import React from "react";

import "./Slider.scss";
import Home1 from "./Home1";
import night from "../../../../../Asset/night_look.jpg";
import day from "../../../../../Asset/day.jpg";
import whts from "../../../../../Asset/whts.jpeg";
import DJI from "../../../../../Asset/day_background.jpg";
import { useSelector } from "react-redux";

function Slider() {
  const langData = useSelector((data) => data.product.Language);
  const homeData = langData?.carousel?.homeData;
  return (
    <>
      {" "}
      <div className='slider-container'>
        <div
          id='carouselExampleControls'
          className='carousel slide'
          data-bs-ride='carousel'
        >
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <Home1
                header={homeData[0].header}
                img={day}
                title={homeData[0].title}
              />
            </div>
            <div className='carousel-item'>
              <Home1
                header={homeData[1].header}
                img={night}
                title={homeData[1].title}
              />
            </div>{" "}
            <div className='carousel-item'>
              <Home1
                header={homeData[2].header}
                img={DJI}
                title={homeData[2].title}
              />
            </div>
          </div>
          <button
            className='carousel-control-prev'
            type='button'
            data-bs-target='#carouselExampleControls'
            data-bs-slide='prev'
          >
            <span
              className='carousel-control-prev-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Previous</span>
          </button>
          <button
            className='carousel-control-next'
            type='button'
            data-bs-target='#carouselExampleControls'
            data-bs-slide='next'
          >
            <span
              className='carousel-control-next-icon'
              aria-hidden='true'
            ></span>
            <span className='visually-hidden'>Next</span>
          </button>
        </div>
      </div>
    </>
  );
}

export default Slider;
