import React, { useEffect } from "react";
import PagesBg from "../Pages_Background/PagesBg";
import Quote from "../About_us/Other/Quote/Quote";
import "./Faq.scss";
import bhandaraData from "../BhandaraBookings/bhandaraDetails.json"
import DataTableCard from "./Card_Container/DataTableCard";
function BhandaraBookings() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='faq-container'>
      <PagesBg title={"Bhandara"} />
      <h2 className="centered-heading">श्री सिद्धबली बाबा मन्दिर में होने वाले भण्डारों की सूची/विवरण</h2>
      <DataTableCard data={bhandaraData} />
      <Quote />
    </div>
  );
}

export default BhandaraBookings;
