import React, { useEffect } from "react";
import PagesBg from "../Pages_Background/PagesBg";
import Quote from "../About_us/Other/Quote/Quote";
import "./Faq.scss";
import Info_card from "./Card_Container/Info_card";
function Faq() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='faq-container'>
      <PagesBg title={"FAQ"} />
      <Info_card />
      <Quote />
    </div>
  );
}

export default Faq;
