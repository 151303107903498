import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
import TempleLogoEng from "../../../Asset/Sidhbali_logo_eng.png";
import TempleLogoHnd from "../../../Asset/Sidhbali_logo_hindi 1.png";
const IconsData = [
  {
    link: "https://www.facebook.com/profile.php?id=100093673647291",
    icon: <FaFacebookF />,
  },
  {
    link: "https://www.instagram.com/shrisidhbalidham/?igshid=OGIzYTJhMTRmYQ%3D%3D&__coig_restricted=1",
    icon: <FaInstagram />,
  },
  { link: "https://twitter.com/sidhbalidham", icon: <FaTwitter /> },
  { link: "https://www.youtube.com/watch?v=cLt67KO6la8", icon: <FaYoutube /> },
];
function Footer() {
  const stateValue = useSelector((data) => data?.product?.state_val);
  return (
    <footer className='sigma_footer-container'>
      <div className='sigma_footer-bottom'>
        <div className='sigma_footer-copyright'>
          <p>
            Copyright © Shri Sidhbali Baba Dham -
            <Link to='/'>2023</Link>
          </p>
        </div>
        <div className='sigma_footer-logo'>
          {stateValue ? (
            <img src={TempleLogoHnd} alt='logo' />
          ) : (
            <img src={TempleLogoEng} alt='logo' />
          )}
        </div>
        <div className='sigma_sm'>
          {IconsData.map((data, index) => {
            return (
              <a
                key={index}
                target='_blank'
                className='icon-link'
                href={data.link}
              >
                {data.icon}
              </a>
            );
          })}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
