import { createSlice } from "@reduxjs/toolkit";
import { Languages } from "../Constant/Language/Language";
const initialState = {
  Language: Languages.Hindi,
  state_val: true,
};

export const LanguageReducer = createSlice({
  name: "LanguageTranslate",
  initialState: initialState,
  reducers: {
    changeLanguage: (state, action) => {
      if (action.payload === false) {
        state.Language = Languages.Englis;
      } else {
        state.Language = Languages.Hindi;
      }
      state.state_val = !state.state_val;
    },
  },
});
