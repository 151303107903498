import React, { useState } from "react";
import "./Quote.scss";
import { BsFillPersonFill, BsFillPencilFill } from "react-icons/bs";
import { BiError } from "react-icons/bi";
import { Alert, Modal } from "reactstrap";
import { MdOutlineEmail } from "react-icons/md";
import img1 from "../../../../../Asset/QuoteImg/SidhabaliImages/1.png";
import img2 from "../../../../../Asset/QuoteImg/SidhabaliImages/12.png";
import img3 from "../../../../../Asset/QuoteImg/SidhabaliImages/2.png";
import img4 from "../../../../../Asset/QuoteImg/SidhabaliImages/8.png";
import img5 from "../../../../../Asset/QuoteImg/SidhabaliImages/5.png";
import img6 from "../../../../../Asset/QuoteImg/SidhabaliImages/6.png";
import axios from "axios";
const imgData = [
  { img: img1, alt: "image1" },
  { img: img2, alt: "image2" },
  { img: img3, alt: "image3" },
  { img: img4, alt: "image4" },
  { img: img5, alt: "image5" },
  { img: img6, alt: "image6" },
];

function Quote() {
  const [disableButton, setDisable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allFields, setAllFields] = useState({
    firstName: "",
    lastName: "",
    subject: "",
    email: "",
    message: "",
  });
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    firstNameError: "",
    lastNameError: "",
    subjectError: "",
    emailError: "",
    messageError: "",
  });

  const validation = (values) => {
    const errors = {
      firstNameError: "",
      lastNameError: "",
      subjectError: "",
      emailError: "",
      messageError: "",
    };
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.firstName) {
      errors.firstNameError = "First name is required.";
    } else {
      errors.firstNameError = "";
    }

    if (!values.subject) {
      errors.subjectError = "Subject is required.";
    } else {
      errors.subjectError = "";
    }
    if (!values.message) {
      errors.messageError = "Message is required.";
    } else if (values.message.length <= 4) {
      errors.messageError = "Length must be greater than 4.";
    } else {
      errors.messageError = "";
    }
    if (!values.email) {
      errors.emailError = "Email is required.";
    } else if (!regex.test(values.email)) {
      errors.emailError = "This is not a valid email address.";
    } else {
      errors.emailError = "";
    }

    return errors;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAllFields({ ...allFields, [name]: value });
    setErrorMessage(validation(allFields));
  };

  const handleSubmit = (e) => {
    setDisable(true);
    console.log("Hello");
    e.preventDefault();
    setShowError(true);
    setErrorMessage(validation(allFields));
    const {
      firstNameError,
      lastNameError,
      subjectError,
      emailError,
      messageError,
    } = errorMessage;
    if (
      !(!allFields.email || emailError) &&
      !(!allFields.message || messageError) &&
      !(!allFields.firstName || firstNameError) &&
      !(!allFields.lastName || lastNameError) &&
      !(!allFields.subject || subjectError)
    ) {
      let postData = JSON.stringify({
        TableName: "sidhbali_messages",
        Item: {
          email_id: allFields.email,
          message: allFields.message,
          first_name: allFields.firstName,
          last_name: allFields.lastName,
          subject: allFields.subject,
        },
      });
      const config = {
        headers: {
          "x-api-key": "GdvEH9ghOU2MrVvfAWQju6f0cfHEAkim1ItoB2QF",
          "Content-Type": "application/json",
        },
      };
      axios.post(
        "https://ib2rrxyafa.execute-api.ap-south-1.amazonaws.com/default/sidhbali_messages_api",
        postData,
        config
      );
      setAllFields({
        firstName: "",
        lastName: "",
        subject: "",
        email: "",
        message: "",
      });
      setErrorMessage({
        firstNameError: "",
        lastNameError: "",
        subjectError: "",
        emailError: "",
        messageError: "",
      });
      setShowError(false);
      setVisible(true);
    }
    setDisable(false);
  };

  const onDismiss = () => setVisible(false);

  return (
    <div className='quote-container'>
      <Modal
        className='quote_modal'
        isOpen={visible}
        toggle={() => onDismiss()}
      >
        <Alert className='alert_popup' toggle={onDismiss} color='info'>
          Message is sent.
        </Alert>
      </Modal>
      <div className='col-lg-6 mb-lg-30'>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className='form-row'>
            <div className='col-lg-6'>
              {errorMessage?.firstNameError.length > 0 && showError ? (
                <p className='error_message'>
                  {errorMessage?.firstNameError}
                  <BiError />
                </p>
              ) : (
                ""
              )}

              <div className='form-group'>
                <i className='far fa-user'>
                  <BsFillPersonFill />
                </i>
                <input
                  type='text'
                  className='form-control transparent'
                  placeholder='First Name'
                  name='firstName'
                  value={allFields.firstName}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className='col-lg-6'>
              {errorMessage?.lastNameError.length > 0 && showError ? (
                <p className='error_message'>
                  {errorMessage?.lastNameError}

                  <BiError />
                </p>
              ) : (
                ""
              )}
              <div className='form-group'>
                <i className='far fa-user'>
                  {" "}
                  <BsFillPersonFill />
                </i>
                <input
                  type='text'
                  className='form-control transparent'
                  placeholder='Last Name'
                  name='lastName'
                  value={allFields.lastName}
                  onChange={(e) => handleChange(e)}
                />{" "}
              </div>
            </div>
            <div className='col-lg-6'>
              {errorMessage?.subjectError.length > 0 && showError ? (
                <p className='error_message'>
                  {errorMessage?.subjectError}

                  <BiError />
                </p>
              ) : (
                ""
              )}
              <div className='form-group'>
                <i className='far fa-pencil'>
                  <BsFillPencilFill />
                </i>
                <input
                  type='text'
                  className='form-control transparent'
                  placeholder='Subject'
                  name='subject'
                  value={allFields.subject}
                  onChange={(e) => handleChange(e)}
                />{" "}
              </div>
            </div>
            <div className='col-lg-6'>
              {errorMessage?.emailError.length > 0 && showError ? (
                <p className='error_message'>
                  {errorMessage?.emailError}
                  <BiError />
                </p>
              ) : (
                ""
              )}
              <div className='form-group'>
                <i className='far fa-envelope'>
                  <MdOutlineEmail />
                </i>
                <input
                  type='text'
                  className='form-control transparent'
                  placeholder='Email Address'
                  name='email'
                  value={allFields.email}
                  onChange={(e) => handleChange(e)}
                />{" "}
              </div>
            </div>
            <div className='col-lg-12'>
              {errorMessage?.messageError.length > 0 && showError ? (
                <p className='error_message'>
                  {errorMessage?.messageError}

                  <BiError />
                </p>
              ) : (
                ""
              )}
              <div className='form-group'>
                <textarea
                  name='message'
                  className='form-control transparent'
                  placeholder='Enter Message'
                  rows='4'
                  value={allFields.message}
                  onChange={(e) => handleChange(e)}
                ></textarea>{" "}
              </div>
            </div>
            <div className='col-lg-12'>
              <button
                type='submit'
                className='sigma_btn-custom d-block w-100'
                name='button'
                disabled={disableButton}
              >
                Send a Message <i className='far fa-arrow-right'></i>{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='img-container'>
        {imgData.map((data, index) => {
          return <img key={index} src={data.img} alt={data.alt} />;
        })}
      </div>
    </div>
  );
}

export default Quote;
