import React, { useState } from "react";
import "./BrodCast.scss";
import PagesBg from "../Pages_Background/PagesBg";
import TempleImage from "../../../Asset/day_background.jpg";
import { BsFillPlayFill } from "react-icons/bs";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { useSelector } from "react-redux";
import omLogo from "../../../Asset/om.png";
// const videoGrpData = [
//   {
//     img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLFI1-AhIesT1APn2g7HqMorvF7AJs3EcpTuOx6hN7Sb4-KEJOAaTiK34ol7nYxraupN0&usqp=CAU",
//     alt: "first_img",
//     videoLink: "https://www.youtube.com/watch?v=TLjYFGor5Ms&t=55s",
//     title: "Varansi at Night",
//   },
//   {
//     img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLFI1-AhIesT1APn2g7HqMorvF7AJs3EcpTuOx6hN7Sb4-KEJOAaTiK34ol7nYxraupN0&usqp=CAU",
//     alt: "first_img",
//     videoLink: "https://www.youtube.com/watch?v=TLjYFGor5Ms&t=55s",
//     title: "Varansi at Night",
//   },
//   {
//     img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLFI1-AhIesT1APn2g7HqMorvF7AJs3EcpTuOx6hN7Sb4-KEJOAaTiK34ol7nYxraupN0&usqp=CAU",
//     alt: "first_img",
//     videoLink: "https://www.youtube.com/watch?v=TLjYFGor5Ms&t=55s",
//     title: "Varansi at Night",
//   },
//   {
//     img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLFI1-AhIesT1APn2g7HqMorvF7AJs3EcpTuOx6hN7Sb4-KEJOAaTiK34ol7nYxraupN0&usqp=CAU",
//     alt: "first_img",
//     videoLink: "https://www.youtube.com/watch?v=1Bor5HtxnMQ",
//     title: "Varansi at Night",
//   },
// ];
function Broadcast() {
  const [videoLink, setVideoLink] = useState("");
  const [modal, setModal] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const [keyboard, setKeyboard] = useState(true);
  const langData = useSelector((data) => data.product.Language);
  const toggle = () => setModal(!modal);

  const handleVideoLink = (val) => {
    setVideoLink(val);

    toggle();
  };
  return (
    <div className='broadcast-container'>
      <PagesBg title='Broadcast' />
      <div className='video-container'>
        <div className='section section-padding'>
          <div className='container'>
            <div className='section-title text-center'>
              <p className='subtitle'>
                <img src={omLogo} alt='logo' />
                {langData?.BroadCast?.main_content?.title}
                <img src={omLogo} alt='logo' />
              </p>
              <h4 className='title'>
                {" "}
                {langData?.BroadCast?.main_content?.subtitle}
              </h4>
            </div>
            <div className='row sigma_broadcast-video'>
              <div className='col-12 mb-5'>
                <div className='row g-0 align-items-center'>
                  <div className='col-lg-6'>
                    <div className='sigma_video-popup-wrap'>
                      <img src={TempleImage} alt='video' />

                      <p
                        onClick={() =>
                          handleVideoLink(
                            "https://www.youtube.com/embed/cLt67KO6la8"
                          )
                        }
                        className='sigma_video-popup popup-youtube'
                      >
                        <BsFillPlayFill />
                      </p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='sigma_box m-0'>
                      {/* <p className='custom-primary mb-0 fw-600 fs-16'>
                        Aug 12, 2022
                      </p> */}
                      <h4 className='title'>
                        {langData?.BroadCast?.main_content?.introTitle}
                      </h4>
                      <p className='m-0'>
                        {langData?.BroadCast?.main_content?.details}
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* {videoGrpData.map((data, index) => {
                return (
                  <div
                    key={index}
                    className='col-lg-3 col-sm-6 mb-30 video-group'
                  >
                    <div className='sigma_video-popup-wrap'>
                      <img src={data.img} alt={data.alt} />
                      <p
                        className='sigma_video-popup popup-sm popup-youtube'
                        onClick={() => handleVideoLink(data.videoLink)}
                      >
                        <BsFillPlayFill />
                      </p>
                    </div>
                    <h6 className='mb-0 mt-3'>{data.title}</h6>
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <ModalBody>
          <iframe src={videoLink}></iframe>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Broadcast;
