import React from "react";
import "./PagesBg.scss";
import omlogo from "../../../Asset/om.png";
function PagesBg({ title }) {
  return (
    <div className='pagesbg-us-main'>
      <div className='pagesbg-us-container'>
        {/* <h1>{title}</h1> */}
        <div className='pagesbg-button-container'>
          <span className='color-grey'>Home</span>
          <img src={omlogo} alt='om-logo' />
          <span>{title.toUpperCase()}</span>
        </div>
      </div>
      <div className=''></div>
    </div>
  );
}

export default PagesBg;
