import React, { useEffect, useState } from "react";
import NavBars from "../NavBar/NavBars";
import "./Template.scss";
import Example from "../Carousel/Content/Home1/Slider";
import Main_Home from "../Carousel/Content/Home1/Main_Home";
import { Route, Routes } from "react-router";
import AboutUs from "../About_us/AboutUs";
import Contact from "../Contact/Contact";
import Faq from "../Faq/Faq";
import Footer from "../Footer/Footer";
import Volunteers from "../Volunteers/Volunteers";
import Preloader from "../Preloader/Preloader";
import { useLocation } from "react-router";
import Broadcast from "../Broadcast/Brodcast";
import BhandaraBookings from "../BhandaraBookings/BhandaraBookings";
function Template() {
  const history = useLocation();
  const [preLoader, setPreloader] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPreloader(false);
    }, 500);

    return () => {
      clearTimeout(timer);
      setPreloader(true);
    };
  }, [history.pathname]);
  return (
    <>
      <div className='template-component'>
        {preLoader ? (
          <Preloader />
        ) : (
          <>
            {" "}
            <div className='nav'>
              <NavBars />
            </div>
            <div className='content-element'>
              <Routes>
                <Route path='/' element={<Main_Home />} />
                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/volunteers' element={<Volunteers />} />
                <Route path='/broadcast' element={<Broadcast />} />
                <Route path='/bhandara-bookings' element={<BhandaraBookings />} />
              </Routes>

              <div className='footer component'>
                <Footer />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Template;
